import React from "react"
import {
    useStaticQuery,
    graphql
} from "gatsby"
import Img from "gatsby-image"
//import PropTypes from 'prop-types'

const GallerySlides = () => {
    const [index, setIndex] = React.useState(0);
    const allImagesQuery = (
        graphql `
            query {
                allFile(
                    filter: {relativeDirectory: {eq: "gallerySlides"}, 
                    extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
                totalCount
                edges {
                    node {
                    base
                    childImageSharp {
                        fluid {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }
                }
                }
            }
        `
    )

    //const length = allImagesQuery.allFile.length - 1;
    const length = 12;

    React.useEffect(() => {
        const timer = setInterval(() => {
            if (index === length) { // total number of images minus 1
                setIndex(0);
            } else {
                setIndex(prev => prev + 1);
            }
        }, 2000); //duration
        return () => clearInterval(timer); //cleanup
    }, [index]); //compare

    const {
        allFile: {
            edges: images
        }, //destructuring
    } = useStaticQuery(allImagesQuery)

    return ( 
        <div>
            <Img style = {
                {
                    width: "100%",
                    height: "100%"
                }
            }
            fluid = {
                images[index].node.childImageSharp.fluid
            }
            alt = {
                images[index].node.base.split(".")[0]
            }
            fadeIn = "true"
            durationFadeIn = {3000}
            backgroundColor = "green" / >
        </div>
    )
}

export default GallerySlides;
