import React from 'react';
import Layout from '../components/Layout';
import GallerySlides from '../components/gallerySlideshow';

const Gallery = () => (
  <div>
    <Layout>
    <div className={"slideshow"}><GallerySlides /></div>
      <div className={"content"}>
        <ul>
          <li>
            <h1>Gallery</h1>
            <div >
              <h2>2015 - Chetwynd 83rd Anniversary</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157650103722235/">2nd January 2015</a></li>
              </ul>
              <h2>2014 - Aruba Senior International Tournament</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157648773312599/">6th November Day 1</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157648779051109/">7th November Day 2</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157648790622237/">8th November Day 3</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157646891523904/">9th November Day 4</a></li>
              </ul>
              <h2>2014 - Chetwynd Club Tournament</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157648921084691/">12th October</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157648779051109/">28th September</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157648790622237/">27th September</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157646891523904/">21st September</a></li>
              </ul>
              <h2>2014 - Chetwynd 2nd Senior International Tournament</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157644244934573/">Easter Weekend 2014</a></li>
              </ul>
              <h2>2014 - East Zone Inter-Club Tournament</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157645436980693/">Chetwynd vs Arima (Men)</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157645166082786/">Chetwynd vs Arima (Women)</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157645169941164/">Chetwynd vs St. Augustine (Women)</a></li>
              </ul>
              <h2>2014 - Chetwynd Tennis Caravan</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157645382114477/">Chetwynd @ BP (July)</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157644545321558/">Chetwynd @ Penal (May)</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157644419259319/">Chetwynd @ Beachcamp (May)</a></li>
              </ul>
              <h2>2013 - Chetwynd Tennis Caravan</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157639422739185/">Chetwynd @ Home (December)</a></li>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157633869762602/">Chetwynd @ Palo Seco (June)</a></li>
              </ul>
              <h2>2013 - Chetwynd 1st Senior International Tournament</h2>
              <ul>
                <li><a href="https://www.flickr.com/photos/mfontenelle/sets/72157633149616723/">Easter Weekend 2013</a></li>
              </ul>
            </div>

          </li>
        </ul>
      </div>
    </Layout>
  </div>
);

export default Gallery;
